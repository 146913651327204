<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M6.2272 12.5209L4.93987 12.1756L5.46454 10.2163C4.67918 9.9266 3.94928 9.50449 3.30654 8.96825L1.8712 10.4043L0.92787 9.46092L2.36387 8.02558C1.55325 7.05476 1.00859 5.89009 0.783203 4.64558L2.0952 4.40625C2.6012 7.20758 5.05187 9.33292 7.9992 9.33292C10.9459 9.33292 13.3972 7.20758 13.9032 4.40625L15.2152 4.64492C14.9901 5.88959 14.4457 7.0545 13.6352 8.02558L15.0705 9.46092L14.1272 10.4043L12.6919 8.96825C12.0491 9.50449 11.3192 9.9266 10.5339 10.2163L11.0585 12.1762L9.7712 12.5209L9.24587 10.5609C8.42077 10.7023 7.57764 10.7023 6.75254 10.5609L6.2272 12.5209Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PasswordLockIcon",
};
</script>
